<template>
  <div
    :id="id"
    class="t3-frame"
    :class="[wrapperClass, computedClasses]"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { T3FrameProps } from '~shared/types'

defineOptions({ inheritAttrs: false })

const props = withDefaults(defineProps<T3FrameProps>(), {
  frameClass: 'default',
  spaceBefore: '',
  spaceAfter: '',
  paddingBefore: '',
  paddingAfter: '',
  fullWidthBackground: false,
  height: 0,
  color: ''
})

const parseClass = (classNamePrefix: string, value: string | number) => {
  if (!(value && value !== 'default')) return
  if (!classNamePrefix) return value
  return `${classNamePrefix}-${value}`
}

const wrapperClass = computed(() => {
  const prefix = props.fullWidthBackground ? '' : 'frame'
  return parseClass(prefix, props.frameClass)
})

const computedClasses = computed(() => {
  return [
    parseClass('space-before', props.spaceBefore),
    parseClass('space-after', props.spaceAfter),
    parseClass('padding-before', props.paddingBefore),
    parseClass('padding-after', props.paddingAfter),
    parseClass('height-frame', props.height),
    parseClass('color', props.color)
  ]
})
</script>
